import React from "react";

const Footer = () => {
  return (
    <p>
      <a
        property="dct:title"
        rel="cc:attributionURL"
        href="https://recipe-ibhl.pages.dev/"
      >
        Recipe Maker v0.3
      </a>{" "}
      by{" "}
      <a
        rel="cc:attributionURL dct:creator"
        property="cc:attributionName"
        href="https://github.com/71xn"
      >
        Finn Lestrange
      </a>{" "}
      is marked with{" "}
      <a
        href="http://creativecommons.org/publicdomain/zero/1.0?ref=chooser-v1"
        target="_blank"
        rel="license noopener noreferrer"
        style={{ display: "inline-block" }}
      >
        CC0 1.0 Universal
        <img
          style={{
            height: "22px",
            marginLeft: "3px",
            verticalAlign: "text-bottom",
          }}
          src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
          alt=""
        />
        <img
          style={{
            height: "22px",
            marginLeft: "3px",
            verticalAlign: "text-bottom",
          }}
          src="https://mirrors.creativecommons.org/presskit/icons/zero.svg?ref=chooser-v1"
          alt=""
        />
      </a>
    </p>
  );
};

export default Footer;
