import React from "react";
import "../styles/recipe.css";

const Recipe = ({ title, image, ingredients, url, totalTime, cuisineType }) => {
  const Time = (props) => {
    const time = props.time;
    if (time !== 0) {
      return <h4>Total Time: {time} minutes.</h4>;
    } else {
      return null;
    }
  };

  return (
    <div>
      <h3>{title}</h3>
      <h4>Cuisine is: {cuisineType}</h4>
      <h4>
        <u>Ingredients:</u>
      </h4>
      <div>
        {ingredients.map((ingredient) => (
          <p>{ingredient.text}</p>
        ))}
      </div>
      <div>
        <h4>
          Instructions{" "}
          <a href={url} target="_blank">
            here
          </a>
        </h4>
      </div>
      <img src={image} alt=""></img>
      <div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Recipe;
