import { useState, useEffect } from "react";
import "./App.css";
import Recipe from "./components/recipe";
import Navigation from "./components/Navigation";
import Form from "./components/Form";
import Footer from "./components/footer";

const App = () => {
  const APP_ID = process.env.REACT_APP_APP_ID;
  const APP_KEY = process.env.REACT_APP_APP_KEY;
  // We can use the useEffect hook to re-render the page when we make a request

  const [recipes, setRecipes] = useState([]);
  const [formData, setFormData] = useState({
    ingredient1: "cheese",
    ingredient2: "carrot",
    ingredient3: "pasta",
    mealType: "",
    additionalIngredient: "",
    time: "0-60",
  });

  useEffect(() => {
    getRecipes();
  }, [formData]);

  const getRecipes = async () => {
    const q =
      formData.ingredient1.toLowerCase() +
      "," +
      formData.ingredient2.toLowerCase() +
      "," +
      formData.ingredient3.toLowerCase() +
      "," +
      formData.additionalIngredient.toLowerCase();
    const time = formData.time.toString();
    const health = formData.mealType.toLowerCase();

    let rurl = `https://api.edamam.com/search?q=${q}&app_id=${APP_ID}&app_key=${APP_KEY}&time=${time}`;

    if (health !== "") {
      rurl = `https://api.edamam.com/search?q=${q}&app_id=${APP_ID}&app_key=${APP_KEY}&health=${health}&time=${time}`;
    } else {
      rurl = `https://api.edamam.com/search?q=${q}&app_id=${APP_ID}&app_key=${APP_KEY}&time=${time}`;
    }

    console.log("ig1: " + q);

    // API https://developer.edamam.com/edamam-docs-recipe-api
    const response = await fetch(rurl);
    const data = await response.json();
    console.log(data);
    //console.log(data.hits[0].recipe.ingredients[0].text);
    setRecipes(data.hits);
  };

  const getFormData = (formData) => {
    console.log(formData);
    setFormData(formData);
  };

  return (
    <div className="App">
      <Navigation />
      <div className="column left">
        <h1>
          <u>Recipes</u>
        </h1>
        {recipes.map((recipe) => (
          <Recipe
            key={recipe.recipe.calories + Math.random()}
            title={recipe.recipe.label}
            calories={recipe.recipe.calories}
            image={recipe.recipe.image}
            ingredients={recipe.recipe.ingredients}
            url={recipe.recipe.url}
            time={recipe.recipe.totalTime}
            cuisineType={recipe.recipe.cuisineType}
          />
        ))}
      </div>
      <div className="column right">
        <Form returnData={getFormData} />
        <div>
          <br />
          <br />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
