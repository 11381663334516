import React, { Component } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  Button,
  createMuiTheme,
  ThemeProvider,
  FormHelperText,
  withStyles,
  TextField,
} from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { Formik } from "formik";
import "./form.css";

const theme = createMuiTheme({
  palette: {
    textColor: "#FFFFFF",
    type: "dark",
  },
  MenuItem: {
    selectedTextColor: "white",
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#028889",
    "&:hover": {
      backgroundColor: "#f79b3b",
    },
  },
}))(Button);

export default class Form extends Component {
  render() {
    return (
      <div>
        <h2>
          <u>Recipe Category Selection</u>
        </h2>
        <br />
        <Formik
          initialValues={{
            ingredient1: "Chicken",
            ingredient2: "Cheese",
            ingredient3: "Pasta",
            mealType: "",
            additionalIngredient: "",
            time: "0-10",
          }}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            // Need to add function call from parent that receives data
            console.log(data);
            //this.props.formDataReturnFunctionFromParent(data);
            this.props.returnData(data);
            setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} onChange={handleChange}>
              <h4>Ingredient Selection</h4>
              <ThemeProvider theme={theme}>
                <FormControl variant="filled">
                  <Select
                    name="ingredient1"
                    value={values.ingredient1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={"Chicken"}>Chicken</MenuItem>
                    <MenuItem value={"Beef"}>Beef</MenuItem>
                    <MenuItem value={"Lamb"}>Lamb</MenuItem>
                    <MenuItem value={"Pork"}>Pork</MenuItem>
                    <MenuItem value={"Egg"}>Egg</MenuItem>
                    <MenuItem value={""}>None</MenuItem>
                  </Select>
                  <FormHelperText>Main Ingredient</FormHelperText>
                </FormControl>
                <FormControl variant="filled">
                  <Select
                    name="ingredient2"
                    value={values.ingredient2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={"Cheese"}>Cheese</MenuItem>
                    <MenuItem value={"Carrot"}>Carrot</MenuItem>
                    <MenuItem value={"Cucumber"}>Cucumber</MenuItem>
                    <MenuItem value={"Eggplant"}>Eggplant</MenuItem>
                    <MenuItem value={"Pea"}>Pea</MenuItem>
                    <MenuItem value={"Asparagus"}>Asparagus</MenuItem>
                    <MenuItem value={"Tomato"}>Tomato</MenuItem>
                    <MenuItem value={"Beans"}>Beans</MenuItem>
                    <MenuItem value={"Onions"}>Onions</MenuItem>
                    <MenuItem value={""}>None</MenuItem>
                  </Select>
                  <FormHelperText>Secondary Ingredient</FormHelperText>
                </FormControl>
                <FormControl variant="filled">
                  <Select
                    name="ingredient3"
                    value={values.ingredient3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={"Pasta"}>Pasta</MenuItem>
                    <MenuItem value={"Rice"}>Rice</MenuItem>
                    <MenuItem value={"Noodles"}>Noodles</MenuItem>
                    <MenuItem value={""}>None</MenuItem>
                  </Select>
                  <FormHelperText>Third Ingredient</FormHelperText>
                </FormControl>
              </ThemeProvider>

              <div>
                <br />
                <h4> Additional Ingredients</h4>
                <br />
              </div>

              <ThemeProvider theme={theme}>
                <FormControl variant="filled">
                  <TextField
                    name="additionalIngredient"
                    value={values.additionalIngredient}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </ThemeProvider>

              <div>
                <br />
                <h4>Cooking Time (minutes)</h4>
              </div>

              <ThemeProvider theme={theme}>
                <FormControl variant="filled">
                  <Select
                    name="time"
                    value={values.time}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={"0-10"}>0-10</MenuItem>
                    <MenuItem value={"0-30"}>0-30</MenuItem>
                    <MenuItem value={"30-60"}>30-60</MenuItem>
                    <MenuItem value={"60%2B"}>60+</MenuItem>
                    <MenuItem value={"1%2B"}>None</MenuItem>
                  </Select>
                </FormControl>
              </ThemeProvider>

              <div>
                <br />
                <h4>Meal Type</h4>
              </div>

              <ThemeProvider theme={theme}>
                <FormControl variant="filled">
                  <Select
                    name="mealType"
                    value={values.mealType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    <MenuItem value={"Gluten-Free"}>Gluten-Free</MenuItem>
                    <MenuItem value={"Wheat-Free"}>Wheat-Free</MenuItem>
                    <MenuItem value={"Peanut-Free"}>Peanut-Free</MenuItem>
                    <MenuItem value={"Soy-Free"}>Soy-Free</MenuItem>
                    <MenuItem value={"Fish-Free"}>Fish-Free</MenuItem>
                    <MenuItem value={"Pork-Free"}>Pork-Free</MenuItem>
                    <MenuItem value={"Tree-Nut-Free"}>Tree-Nut-Free</MenuItem>
                    <MenuItem value={"Dairy-Free"}>Dairy-Free</MenuItem>
                  </Select>
                  <FormHelperText>Meal Type</FormHelperText>
                </FormControl>
              </ThemeProvider>
              <br />
              <div>
                <br />
                <ThemeProvider theme={theme}>
                  <ColorButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    <FastfoodIcon />
                    Load Recipes
                  </ColorButton>
                </ThemeProvider>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
